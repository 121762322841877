module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GC Insider","short_name":"GC Insider","icons":[{"src":"/android-chrome-192x192.png?v=bOXOnmm4jn","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png?v=bOXOnmm4jn","sizes":"512x512","type":"image/png"}],"start_url":"/?source=pwa","scope":"/","background_color":"#FAFAFA","theme_color":"#673AB7","display":"standalone","orientation":"portrait","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"gcinsider.com"},
    }]
